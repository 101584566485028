import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StringParam, useQueryParam} from 'use-query-params';
import styled from 'styled-components';
import PlayerContextProvider from '../Player/context/playerContext';
import Player from '../Player/Player';
import BasketLicenseList from './BasketLicenseList';
import BasketTrackList from './BasketTrackList';
import {
	applyCouponBasket, deleteAllTrackBasket,
	getBasketSubscribesPrice,
	getBasketTracksPrice,
	getTracksBasket,
} from '../../store/actions/basketActions';
import BasketFooter from './BasketFooter';
import {setActiveLicense, setPayType} from '../../store/reducers/basketSlice';
import BasketModal from './BasketModal';
import {getPaymentProfile} from '../../store/actions/userActions';
import BasketSubscribeItem from './BasketSubscribeItem';
import Loader from '../../components/Loader';

const StyledCart = styled.div`
  max-height: ${({isSubscribe}) => (isSubscribe ? 'auto' : '80vh')};
  overflow-Y: auto;
  padding: 0 50px 100px;
`;

const Basket = () => {
	const dispatch = useDispatch();
	const [subscribeId, setSubscribeId] = useQueryParam('subscribeId', StringParam);
	const {
		tracks, activeLicense, couponName, isLoading, message,
	} = useSelector((state) => state.basket);
	const modal = useRef(null);

	const deleteSubscribe = () => {
		setSubscribeId(undefined);
	};

	const selectLicenseHandler = (id) => {
		if (!!subscribeId) {
			setSubscribeId(id);
		} else {
			dispatch(setActiveLicense({id}));
		}
	};

	const openDialog = (type) => {
		dispatch(setPayType(type));
		modal.current.classList.remove('page__modal');
	};

	const closeDialog = () => {
		modal.current.classList.add('page__modal');
	};

	useEffect(() => {
		if (couponName && subscribeId) {
			dispatch(applyCouponBasket({coupon_name: couponName}));
		}
	}, [activeLicense, couponName, dispatch, subscribeId]);

	useEffect(() => {
		const headerLink = document.querySelector('.is-current');
		headerLink.classList.remove('is-current');
	}, []);

	useEffect(() => {
		const getUserBasket = async () => {
			await dispatch(getPaymentProfile());
		};

		if (subscribeId) {
			getUserBasket()
				.then(() => dispatch(getBasketSubscribesPrice({subscribeId})))
				.then(() => dispatch(setActiveLicense({id: subscribeId})));
		} else {
			getUserBasket()
				.then(() => dispatch(getBasketTracksPrice()));
			// .then(() => dispatch(getTracksBasket()));
		}
	}, [dispatch, subscribeId]);

	useEffect(() => {
		if (activeLicense.code && !subscribeId) {
			dispatch(getTracksBasket());
		}
	}, [activeLicense, subscribeId, dispatch]);

	const isTracksBasket = !subscribeId && !!tracks.length;

	return (
		<PlayerContextProvider>
			<StyledCart className="cart" isSubscribe={!!subscribeId}>
				<h2 className="title--weight--bold text--extra-large pb-5">Корзина</h2>
				{
					isLoading
						? <div className="d-flex align-items-center justify-content-center w-100 h-100"><Loader/></div>
						: (
							<>
								{
									message
										? (
											<div className="cart__list-header">
												<div className="row justify-content-between align-items-center">
													<div className="col-auto">
														<p className="title--weight--medium text--middle">{message}</p>
													</div>
												</div>
											</div>
										)
										: (
											<>{
												(!!subscribeId || !!tracks.length) && (
													<>
														<BasketLicenseList onClick={selectLicenseHandler}/>
														{
															isTracksBasket
																? <BasketTrackList/>
																: <BasketSubscribeItem deleteSubscribe={deleteSubscribe}/>
														}
														<BasketFooter openDialog={openDialog}/>
													</>
												)
											}
											</>
										)
								}
							</>
						)
				}
			</StyledCart>
			{
				isTracksBasket && <Player/>
			}
			<BasketModal ref={modal} closeDialog={closeDialog}/>
		</PlayerContextProvider>

	);
};

export default Basket;
