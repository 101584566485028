import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useFilter} from './context/filterContext';

const StyledLink = styled.a`
  cursor: pointer;
`;

const CatalogFilterListItem = ({filter}) => {
	const {filterId, setFilterHandler, choices} = useFilter();
	const [isInvolved, setIsInvolved] = useState(false);

	useEffect(() => {
		const arrChoices = Object.keys(choices).filter((key) => !!choices[key].length);
		setIsInvolved(arrChoices.includes(filter.id));
	}, [filter, choices]);

	return (
		<li onClick={setFilterHandler(filter.id)}>
			<StyledLink
				className={`catalog__filter-link
			 ${filterId === filter.id ? 'is-active' : ''}
			 ${isInvolved ? 'is-involved' : ''}`}
				data-filter-button="catalog-filter-id--genre"
			><span>{filter.title}</span>
			</StyledLink>
		</li>
	);
};

export default CatalogFilterListItem;
