import React, {
	createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getTrackPath} from '../../../utils/track-utils';
import {setCurrentTrack, setPlayerList} from '../../../store/reducers/playerSlice';
import {addFavorite, addListened, deleteFavorite} from '../../../store/actions/userListActions';

export const PlayerContext = createContext(null);
export const usePlayer = () => {
	return useContext(PlayerContext);
};

const PlayerContextProvider = ({children}) => {
	const dispatch = useDispatch();
	const {currentTrack, loadingTrackId, playerList} = useSelector((state) => state.player);
	const {page, isAuth} = useSelector((state) => state.user);
	const {tracks: catalogTracks} = useSelector((state) => state.catalog);
	const {favorite, listened} = useSelector((state) => state.userList);
	const [src, setSrc] = useState(null);
	const [arrows, setArrows] = useState({
		prev: false,
		next: false,
	});

	const [playing, setPlaying] = useState(false);
	const [position, setPosition] = useState(0);
	const [currentPosition, setCurrentPosition] = useState(0);
	const [currentId, setCurrentId] = useState(null);
	const [progress, setProgress] = useState(0);
	const [progress2, setProgress2] = useState(0);
	const [loading, setLoading] = useState(false);
	const [tracks, setTracks] = useState([]);
	const [play, setPlay] = useState(false);

	const [barHeight, setBarHeight] = useState(1);
	const [barWidth, setBarWidth] = useState(2);
	const [barGap, setBarGap] = useState(1);

	useEffect(() => {
		switch (page) {
			case 'history':
				setTracks(listened);
				break;
			case 'favorite':
				setTracks(favorite);
				break;
			default:
				setTracks(catalogTracks);
		}
	}, [catalogTracks, favorite, listened, page]);

	const favoriteHandler = useCallback((track, isFavorite) => {
		if (isAuth) {
			if (track.id !== loadingTrackId) {
				if (isFavorite) {
					dispatch(deleteFavorite({
						trackId: track.id,
					}));
				} else if (track.src) {
					dispatch(addFavorite({
						trackId: track.id,
					}));
				}
			}
		} else {
			const regButton = document.querySelector('[data-modal-open="modal--authorization"]');
			if (regButton) {
				regButton.click();
			}
		}
	}, [dispatch, isAuth, loadingTrackId]);

	const setCurrentPositionHandler = useCallback((pos, id) => {
		setCurrentPosition(pos);
		setCurrentId(id);
	}, []);

	const resetPlayer = () => {
		setPlaying(false);
		setLoading(false);
		setProgress(0);
		setPosition(0);
		setCurrentPosition(0);
	};

	const nextTrack = useCallback(async () => {
		await resetPlayer();
		const trackIndex = playerList.findIndex((track) => track.id === currentTrack.id);
		if (playerList[trackIndex + 1] && playerList[trackIndex + 1].src) {
			dispatch(setCurrentTrack(playerList[trackIndex + 1]));
			setSrc(playerList[trackIndex + 1].src);
		} else if (playerList[0].src) {
			dispatch(setCurrentTrack(playerList[0]));
			setSrc(playerList[0].src);
		}
	}, [currentTrack.id, dispatch, playerList]);

	const prevTrack = useCallback(async () => {
		await resetPlayer();
		const trackIndex = playerList.findIndex((track) => track.id === currentTrack.id);
		if (playerList[trackIndex - 1] && playerList[trackIndex - 1].src) {
			dispatch(setCurrentTrack(playerList[trackIndex - 1]));
			setSrc(playerList[trackIndex - 1].src);
		} else if (playerList[playerList.length - 1].src) {
			// dispatch(setCurrentTrack(playerList[playerList.length - 1]));
			// setSrc(playerList[playerList.length - 1].src);
		}
	}, [currentTrack.id, dispatch, playerList]);

	const togglePlayPause = useCallback(() => {
		if (currentTrack.id) {
			setPlay(true);
			if (play) {
				setPlaying((prev) => !prev);
			}
		}
	}, [currentTrack, play]);

	const loadTrack = useCallback(async (t) => {
		await resetPlayer();
		if (t) {
			if (currentTrack.id !== t.id) {
				dispatch(setCurrentTrack(t));
				if (t.src) {
					dispatch(setPlayerList([...tracks].filter((t) => t.src)));
				}
			}
			if (!window.location.pathname.includes('personal')) {
				dispatch(addListened({trackId: t.id}));
			}
		}
	}, [currentTrack.id, dispatch, tracks]);

	const preparePeaks = (peaks) => {
		return !!peaks ? peaks.map((peak) => (+peak) / 100) : [];
	};

	useEffect(() => {
		const realTracks = [...tracks].filter((t) => !!t.src);
		if (!!realTracks.length) {
			dispatch(setPlayerList(realTracks));
		}
	}, [dispatch, tracks]);

	useEffect(() => {
		if (currentTrack) {
			const src = currentTrack.src ? getTrackPath(currentTrack.src) : '';
			setSrc(src);
		}
	}, [currentTrack]);

	return (
		<PlayerContext.Provider value={{
			togglePlayPause,
			currentPosition,
			setCurrentPosition,
			setCurrentPositionHandler,
			setCurrentId,
			currentId,
			loading,
			setLoading,
			progress,
			setProgress,
			position,
			setPosition,
			playing,
			setPlaying,
			favoriteHandler,
			src,
			loadTrack,
			prevTrack,
			nextTrack,
			preparePeaks,
			progress2,
			setProgress2,
			barHeight,
			barWidth,
			barGap,
			setBarHeight,
			setBarWidth,
			setBarGap,
			play,
			setPlay,
			arrows,
			setArrows,
		}}
		>
			{children}
		</PlayerContext.Provider>
	);
};
export default PlayerContextProvider;
