import React, {
	createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import {useSelector} from 'react-redux';
import {getSeconds, getTimeString} from '../../../utils/date-utils';

export const FilterContext = createContext(null);
export const useFilter = () => {
	return useContext(FilterContext);
};

const FilterContextProvider = (props) => {
	const {settings, variants} = useSelector((state) => state.filters);
	const [filterId, setFilterId] = useState(null);
	const [choices, setChoices] = useState({});
	const [ranges, setRanges] = useState({});
	const [radios, setRadios] = useState({});
	const [length, setLength] = useState({min: '', max: ''});
	const [filters, setFilters] = useState(null);
	const [searches, setSearches] = useState([]);
	const [name, setName] = useState('');
	const [search, setSearch] = useState(undefined);
	const {children} = props;

	const setFilterHandler = (id) => {
		return () => {
			setFilterId((currentFilterId) => {
				return currentFilterId === id ? null : id;
			});
		};
	};

	const clearFilters = () => {
		setChoices({});
		setRanges({});
		setRadios({});
		setName('');
		setLength({min: '', max: ''});
	};

	const getFilters = useCallback(() => {
		const currentFilters = {};
		Object.keys(choices).forEach((key) => {
			if (choices[key]) {
				currentFilters[key] = choices[key].sort().join('$$');
			} else {
				delete currentFilters[key];
			}
		});
		Object.keys(ranges).forEach((key) => {
			if (ranges[key].length) {
				const [min, max] = ranges[key];
				currentFilters[`${key}_min`] = min;
				currentFilters[`${key}_max`] = max;
			}
		});
		if (length.max !== '00:00') {
			let lengthMin = length.min;
			if (!lengthMin) {
				lengthMin = '00:00';
			}
			currentFilters.length_min = getSeconds(lengthMin);
			if (!!length.max) {
				currentFilters.length_max = getSeconds(length.max);
			}
		}
		return {...radios, ...currentFilters};
	}, [choices, length, radios, ranges]);

	useEffect(() => {
		if (variants) {
			const withChoices = ['checkbox', 'radio'];
			const withMinMax = ['range', 'length'];
			const filters = settings.map((setting) => {
				if (withChoices.includes(setting.type)) {
					const settingChoices = variants[setting.id] || [];
					return {...setting, choices: settingChoices};
				}
				if (withMinMax.includes(setting.type)) {
					const min = variants[`${setting.id}_min`];
					const max = variants[`${setting.id}_max`];
					if (!setting.defaultValue && setting.type === 'range') {
						return {
							...setting, min, max, defaultValue: [min, max],
						};
					}
					if (setting.type === 'length') {
						const length = {};
						length.min = getTimeString(min);
						length.max = getTimeString(max);
						return {...setting, min: length.min, max: length.max};
					}
				}
				return setting;
			});
			setFilters(filters);
		}
	}, [settings, variants]);

	return (
		<FilterContext.Provider value={{
			filterId,
			choices,
			setChoices,
			ranges,
			setRanges,
			length,
			setLength,
			radios,
			setRadios,
			filters,
			searches,
			setSearches,
			name,
			setName,
			search,
			setSearch,
			setFilterHandler,
			clearFilters,
			getFilters,
		}}
		>
			{children}
		</FilterContext.Provider>
	);
};

export default FilterContextProvider;
