import is from 'is_js';
import {$data} from '../../helpers/data';

export default class Model {

  constructor(options = {}) {

    this.defaults = {
      onCreate: null,
      onInit: null,
      onDestroy: null,
      name: null,
      rootElementId: null,
      debug: true,
      notifyRootAbsence: false
    };

    this.options = $data.deepAssign(this.defaults, options);
    this.name = options.name;
    this.rootElement = this.rootEl;

    this.checkAndRunCallback(this.options.onCreate);
  }

  get rootEl() {
    const {rootElementId} = this.options, el = document.getElementById(rootElementId);

    if (
      is.string(rootElementId)
      &&
      rootElementId !== ''
      &&
      el
    ) return el;

    return null
  }

  checkAndRunCallback(cb) {

    if (is.function(cb)) cb.call(this, this);
    return this;
  };

  init() {
    this.checkAndRunCallback(this.options.onInit);
  }
  destroy() {
    this.checkAndRunCallback(this.options.onDestroy);
  }
}