import React, {useEffect, useState} from 'react';
import {Collapse} from 'react-collapse';
import styled from 'styled-components';
import {MdDownload, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {Popover} from 'antd';
import {addTrack} from '../../store/actions/subscribeActions';

const StyledCollapse = styled.div`
  position: relative;

  & .collapse {
    &__toggle {
      position: relative;

      &--icon {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      &_disabled {
      cursor: default;
      }
    }

    &__container {
      position: absolute;
      top: 37px;
      z-index: 1;
      width: 100%;
    }

    &__list {
      box-shadow: 0 4px 30px rgba(179, 179, 179, 0.5);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background: white;
      padding: 3px 6px;
      font-size: 14px;

      li {
        border-bottom: 1px solid #E3E3E3;
      }
    }

    &__button {
      background-color: #10DB92;
      border-radius: 3px;
      transition-property: background-color, box-shadow, color, border-color;
      transition-duration: 0.125s;
      transition-timing-function: linear;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      padding: 2px 5px;

      &:hover {
        background-color: #01C880;
      }
    }

    &__text {
      &--mute {
        font-size: 12px;
        color: #b3b3b3;
      }
    }

    &__bottom {
      height: 50px;
      width: 100%
    }

  }
`;

const CatalogTrackDownloadSelect = ({trackId, downloadOpen}) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const {subscribes} = useSelector((state) => state.user);
	const setOpenHandler = () => {
		setOpen((prev) => !prev);
	};
	const downloadTrack = (subscribeId, type = 'mp3') => {
		dispatch(addTrack({subscribeId, trackId, type}));
	};

	useEffect(() => {
		setOpen(downloadOpen);
	}, [downloadOpen]);


	const withSubscribes = !!subscribes.length;
	return (
		<StyledCollapse>
			{
				withSubscribes
					? (
						<a
							className="collapse__toggle button button--default waves-light button--waves button--success"
							onClick={setOpenHandler}
						>

							<span>Скачать по подписке</span>
							<span className="collapse__toggle--icon">
								{
									open
										? <MdOutlineKeyboardArrowUp size={20}/>
										: <MdOutlineKeyboardArrowDown size={20}/>
								}
       </span>
						</a>
					)
					: (
						<Popover
							placement="topRight"
							content={(
								<p style={{fontSize: '16px'}}>Вы можете приобрести подписку <br/> и скачивать треки выгодно!
								</p>
							)}
						>
							<a
								className="collapse__toggle button button--default waves-light button--waves button--gray collapse__toggle_disabled"
							>
								<span>Скачать по подписке</span>
							</a>
						</Popover>
					)
			}

			<div className="collapse__container">
				<Collapse isOpened={open}>
					<ul className="collapse__list">
						{
							subscribes.filter(({freeCountTracks}) => !!freeCountTracks)
								.map(({
									id, typeSubscription,
									freeCountTracks, totalCountTracks,
								}) => {
									return (
										<li key={id} className="my-2">
											<div className="d-flex justify-content-between">
												<strong className="mr-1">
													{typeSubscription}
												</strong>
												<span className="collapse__text--mute">
												Скачано <strong>{freeCountTracks}</strong>
													{/* &nbsp;( из <strong>{totalCountTracks}</strong> ) */}
            </span>
											</div>
											<div className="d-flex align-content-center my-3">
												<div
													className="collapse__button mr-2"
													onClick={() => downloadTrack(id, 'wav')}
												>
													<MdDownload/>
													<span className="ml-1">WAV 16 bit</span>
												</div>
												<div
													className="collapse__button"
													onClick={() => downloadTrack(id)}
												>
													<MdDownload/>
													<span className="ml-1">MP3 320 kbps</span>
												</div>
											</div>
										</li>
									);
								})
						}

					</ul>
				</Collapse>
				<div className="collapse__bottom"/>
			</div>
		</StyledCollapse>
	);
};

export default CatalogTrackDownloadSelect;
