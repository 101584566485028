import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {useWindowSize} from 'react-use';
import CatalogAlbumsCard from './CatalogAlbumsCard';
import {setLastAlbumOffset} from '../../store/reducers/catalogSlice';

const StyledList = styled.div`
display: grid;
align-items: center;
gap: 20px; 
margin-bottom: 20px;
${({rowSize}) => css`
grid-template-columns:  repeat(${rowSize}, 1fr);
`}
`;

const CatalogAlbumList = () => {
	const dispatch = useDispatch();
	const {albums, lastAlbumOffset} = useSelector((state) => state.catalog);
	const [rows, setRows] = useState([]);
	const [size, setSize] = useState(5);
	const {width} = useWindowSize();

	useEffect(() => {
		const result = albums.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / size);
			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = [];
			}
			resultArray[chunkIndex].push(item);
			return resultArray;
		}, []);
		setRows(result);
	}, [albums, size]);

	useEffect(() => {
		if (!!rows.length && lastAlbumOffset) {
			const $scrollableDiv = document.getElementById('scrollableDiv');
			if ($scrollableDiv) {
				$scrollableDiv.scrollTo({
					top: lastAlbumOffset,
					behavior: 'smooth',
				});
				dispatch(setLastAlbumOffset(0));
			}
		}
	}, [lastAlbumOffset, dispatch, rows]);

	useEffect(() => {
		if (width > 1400) {
			setSize(8);
		} else if (width > 1200 && width <= 1400) {
			setSize(6);
		} else if (width > 800 && width <= 1200) {
			setSize(4);
		} else {
			setSize(2);
		}
	}, [width]);

	return (
		<>
			{
				!!rows.length && rows.map((row, idx) => {
					return (
						<StyledList key={idx} rowSize={size}>
							{
								row.map((album) => (
									<CatalogAlbumsCard
										key={album.id}
										album={album}
										rowIndex={idx + 1}
									/>
								))
							}
						</StyledList>
					);
				})
			}
		</>
	);
};

export default CatalogAlbumList;
