import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import CatalogFilterList from './CatalogFilterList';
import CatalogFilterChoiceList from './CatalogFilterChoiceList';
import {getFilterData} from '../../store/actions/filterActions';
import {useFilter} from './context/filterContext';
import CatalogFavoriteList from './CatalogFavoriteList';

const StyledForm = styled.form`
		position: relative;
		z-index: 999;
		height: calc(100% - 120px);
	`;

const StyledBlockPage = styled.div`
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index: 998;
`;

const CatalogFilter = () => {
	const dispatch = useDispatch();
	const {favorite, listened} = useSelector((state) => state.userList);
	const [currentFilter, setCurrentFilter] = useState(null);
	const [openFavorite, setOpenFavorite] = useState(false);
	const [openHistory, setOpenHistory] = useState(false);
	const {filterId, filters, setFilterHandler} = useFilter();

	const setOpenFavoriteHandler = () => {
		setOpenFavorite((prev) => {
			if (!prev) {
				setFilterHandler(null)();
			}
			return !prev;
		});
		setOpenHistory(false);
	};

	const setOpenHistoryHandler = () => {
		setOpenHistory((prev) => {
			if (!prev) {
				setFilterHandler(null)();
			}
			return !prev;
		});
		setOpenFavorite(false);
	};

	const closeAll = () => {
		setOpenHistory(false);
		setOpenFavorite(false);
		setFilterHandler(null)();
	};

	useEffect(() => {
		dispatch(getFilterData());
	}, [dispatch]);

	useEffect(() => {
		if (filters) {
			const filter = filters.find((item) => item.id === filterId);
			if (filter) {
				setCurrentFilter(filter);
			}
		}
		if (filterId) {
			setOpenFavorite(false);
			setOpenHistory(false);
		}
	}, [filters, filterId]);

	return (
		<>
			<StyledForm className="catalog__filter js-catalog-page-filter" noValidate id="js-catalog-filter">
				<CatalogFilterList
					setOpenFavoriteHandler={setOpenFavoriteHandler}
					setOpenHistoryHandler={setOpenHistoryHandler}
					favoriteOpen={!filterId && openFavorite}
					historyOpen={!filterId && openHistory}
				/>
				{
					(currentFilter && filterId)
					&& <CatalogFilterChoiceList currentFilter={currentFilter}/>
				}
				{
					(!filterId && openFavorite && !openHistory)
					&& <CatalogFavoriteList list={favorite}/>
				}
				{
					(!filterId && openHistory && !openFavorite)
					&& <CatalogFavoriteList list={listened}/>
				}
			</StyledForm>
			{
				((currentFilter && filterId)
					|| (!filterId && openFavorite && !openHistory)
					|| (!filterId && openHistory && !openFavorite))
				&& <StyledBlockPage onClick={closeAll}/>
			}

		</>
	);
};

export default CatalogFilter;
