import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';
import { isElement, loop, preventDefault } from '../../helpers/_utilities';

const { get, hasClass, removeClass, addClass, toggleClass, exist } = $dom;

export default function initCollapse(
  rootSelector = '.js-collapse-root'
) {
  // const
  //   buttonSelector = `${rootSelector}-button`,
  //   contentSelector = `${rootSelector}-content`
  //   ;
  
  const collapseRootSelector = '.js-collapse-root';
  const collapseButtonSelector = '.js-collapse-button';
  const collapseContentSelector = '.js-collapse-content';

  if (exist([collapseRootSelector, collapseButtonSelector, collapseContentSelector])) {

    let opened = [];

    const { active, disabled } = variables.classNames;

    const collapseHandler = event => {
      const target = event.target.closest(collapseRootSelector);

      console.log(target);

      toggleClass(target, active);
      toggleClass(get(collapseButtonSelector, target), active);
      toggleClass(get(collapseContentSelector, target), active);
    }

    const init = () => {
      $events.delegate
        .on('click tap', collapseRootSelector, collapseHandler)
        // .on('click tap', '.js-scrollup', () => {
        //   console.log('13232131');
        //   window.scrollTo({ top: 0, behavior: 'smooth' });
        // });
    };

    const destroy = () => {
      $events.delegate
        .off('click tap', collapseRootSelector, collapseHandler);
    }
      ;


    return { init, destroy }
  }

  return false;
}