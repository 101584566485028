import React from 'react';
import CatalogFavoriteListItem from './CatalogFavoriteListItem';
import FilterList from '../../components/FilterList';

const CatalogFavoriteList = ({list}) => {
	return (

		<FilterList width={24}>
			<li className="catalog__filter-settings-item">
				<ul className="catalog__filter-settings-item-inner" style={{overflowY: 'auto'}}>
					<li>
						{
							!!list.length && list.filter((track) => track.src)
								.map((track) => <CatalogFavoriteListItem key={track.id} track={track}/>)
						}
					</li>
				</ul>
			</li>
		</FilterList>
	);
};

export default CatalogFavoriteList;
