import {$dom} from '../../helpers/dom';
import {$data} from '../../helpers/data';
import Navigo from 'navigo';
import variables from '../../variables';

const {
  callAll,
  attr,
  addClass,
  removeClass
} = $dom;

export default class Router {
  constructor(options = {}) {

    this.defaults = {
      currentClassName: variables.classNames.active,
      name: null
    };

    this.options = $data.deepAssign(this.defaults, options);
    this.navigo = null;
    this.pageInstance = null;
    this.name = this.options.name;
    this.buttonsAttr = `data-${this.name}-router-link`;
    this.buttonsSelector = `[${this.buttonsAttr}]`;
    this.mainNavClassName = 'js-catalog-nav-link';
    this.mainNavs = [];

    this.setActiveControl = this.setActiveControl.bind(this);
  }

  setActiveControl(match) {
    callAll(`${this.buttonsSelector}.${this.mainNavClassName}`, btn => {
      const href = attr(btn, 'href');

      this.mainNavs.push(href)

      if (href.includes(match.url.split('/')[0])) {
        addClass(btn, this.options.currentClassName)
      } else {
        removeClass(btn, this.options.currentClassName)
      }
    });
  }

  listeners(action) {
    switch (action) {
      case 'add':


        this.navigo
          .on('/collections', this.pageInstance.renderer.renderCollections)
          .on('/collections/:id', this.pageInstance.renderer.renderCollectionAlbums)
          .on('/collections/:id/album/:id', this.pageInstance.renderer.detailedRender)
          .on('/albums', this.pageInstance.renderer.renderAlbums)
          .on('/albums/:id', this.pageInstance.renderer.detailedRender)
          .on('/tracks', this.pageInstance.renderer.renderTracks)
          .on('/playlists', this.pageInstance.renderer.renderPlaylists)
          .on('/playlists/:id', this.pageInstance.renderer.detailedRender)
          .on('*', this.setActiveControl)
          .resolve();

        break;

      case 'remove':
        if (this.navigo instanceof Navigo) this.navigo.destroy();

        this.navigo = null;
        break;
    }
  }

  init(pageInstance) {
    this.pageInstance = pageInstance;

    this.navigo = new Navigo('/', {
      strategy: 'ALL',
      hash: true,
      linksSelector: this.buttonsSelector
    });

    this.listeners('add');
  }

  destroy() {
    this.pageInstance = null;
    this.listeners('remove');
  }
}


