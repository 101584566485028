import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import Player from '../Player/Player';
import PlayerContextProvider from '../Player/context/playerContext';
import {getTracksIdsBySubscription} from '../../store/actions/userListActions';
import CatalogTrackList from '../catalog/CatalogTrackList';
import {scrollBar} from '../../styles/sc-mixins';
import {setPage} from '../../store/reducers/userSlice';
import Loader from '../../components/Loader';
import CatalogContextProvider from '../catalog/context/catalogContext';
import FilterContextProvider from '../catalog/context/filterContext';
import {getPaymentProfile} from '../../store/actions/userActions';

const StyledList = styled.ul`
  overflow-y: auto;
  transition: .25s ease-in;
   height: 63vh;
  ${scrollBar()};
`;
const StyledContainer = styled.div`
  padding: 30px 50px 110px;
   height: calc(100vh - 150px);
`;

const Subscribes = () => {
	const dispatch = useDispatch();
	const {subscribes, isListenedListLoading} = useSelector((state) => state.userList);

	useEffect(() => {
		const id = window.location.pathname.split('/')[3];
		dispatch(getTracksIdsBySubscription(id));
		dispatch(getPaymentProfile());
	}, [dispatch]);

	useEffect(() => {
		dispatch(setPage('subscribes'));
	}, [dispatch]);

	return (
		<CatalogContextProvider>
			<FilterContextProvider>
				<PlayerContextProvider>
					{
						isListenedListLoading
							? (
								<div className="h-100 w-100 d-flex align-items-center justify-content-center">
									<Loader/>
								</div>
							)
							: (
								<StyledContainer>
									<div className="mb-3">
										<div className="cart__list-header" style={{fontSize: 18}}>
											{
												!!subscribes.length
													? <span>Треки в подписке: {subscribes.length}</span>
													: <span>Список треков в подписке пуст </span>
											}
										</div>
									</div>
									<StyledList>
										{
											!!subscribes.length
											&& <CatalogTrackList tracks={subscribes} isLoading={isListenedListLoading}/>
										}
									</StyledList>
								</StyledContainer>
							)
					}
					<Player/>
				</PlayerContextProvider>
			</FilterContextProvider>
		</CatalogContextProvider>
	);
};

export default Subscribes;
