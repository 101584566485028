import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AutoComplete, Input} from 'antd';
import styled, {css} from 'styled-components';
import {AiOutlineClose, AiOutlineSearch} from 'react-icons/ai';
import {searchTracks} from '../../store/actions/catalogActions';
import {useCatalog} from '../catalog/context/catalogContext';
import SearchChoices from './SearchChoices';
import {setCatalogProperty} from '../../store/reducers/catalogSlice';
import {useFilter} from '../catalog/context/filterContext';

const StyledInput = styled(Input)`
  background-color: transparent;
  border: 0 none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 100%;

  * {
    width: 100%
  }
  input {
  min-width: 500px;
  height: 40px;
  }

`;

const StyledIcon = styled.div`
	padding: 0 20px;
	cursor: default;			
	${(props) => props.button && css`
		cursor: pointer;
	`}
`;

const SearchForm = () => {
	const dispatch = useDispatch();
	const {
		setPageHandler, setTrack, sort, albumId, type,
	} = useCatalog();
	const {
		name, setName, getFilters, clearFilters, search, setSearch,
	} = useFilter();

	const [data, setData] = useState([]);
	const {trackSearch} = useSelector((state) => state.catalog);

	const onFocusHandler = useCallback(() => {
		const acceptedTypes = ['albums', 'tracks'];
		if (!acceptedTypes.includes(type) || (type === 'albums' && !albumId)) {
			setPageHandler('tracks')();
		}
	}, [albumId, setPageHandler, type]);

	const searchHandler = (searchValue) => {
		setName(searchValue);
	};

	const changeHandler = (changeValue) => {
		setSearch(changeValue);
	};

	const selectHandler = async (code, option) => {
		if (type !== 'tracks') {
			await setPageHandler('tracks')();
		}
		await clearFilters();
		setSearch(option.name);
		setTrack(code);
	};

	const setCatalogTracks = (evt) => {
		if (evt.keyCode === 13) {
			evt.preventDefault();
		}
	};

	const clearHandler = async () => {
		setName('');
		setSearch('');
		setTrack(undefined);
		dispatch(setCatalogProperty({property: 'trackSearch', value: []}));
	};

	useEffect(() => {
		const filters = {...getFilters()};
		if (name) {
			if (name.length >= 2) {
				const delay = setTimeout(() => {
					dispatch(searchTracks({
						name, filters, sort, album_code: albumId,
					}));
				}, 300);
				return () => {
					clearTimeout(delay);
				};
			}
		}
		return () => {
		};
	}, [dispatch, name, getFilters, sort, albumId]);

	useEffect(() => {
		let options = [];
		if (!!trackSearch.length) {
			options = trackSearch.map((track) => {
				const {id, name: trackName, code} = track;
				return {
					id,
					value: code,
					name: trackName,
					label: (
						<div className="">
							<span>{trackName}</span>
						</div>
					),
				};
			});
		}
		setData(options);
	}, [trackSearch]);

	return (
		<form className="header__search header__search--extended">
			<div className="d-flex align-items-center header__search-output">

				<SearchChoices/>

				<AutoComplete
					className="header__search-field"
					dropdownMatchSelectWidth={800}
					options={data}
					value={!!search ? search : ''}
					onSearch={searchHandler}
					onSelect={selectHandler}
					onChange={changeHandler}
					onKeyDown={setCatalogTracks}
					onFocus={onFocusHandler}
				>
					<StyledInput placeholder="Поиск трека"/>
				</AutoComplete>
				<StyledIcon button={!!search}>
					{
						!!search
							? (
								<span onClick={clearHandler} style={{width: 22}}><AiOutlineClose color="#717171" size={20}/></span>
							)
							: <span style={{width: 22}}><AiOutlineSearch color="#717171" size={20}/></span>
					}
				</StyledIcon>

			</div>
		</form>
	);
};

export default SearchForm;
