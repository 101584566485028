import React from 'react';
import { MdFavorite } from 'react-icons/md';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';

const StyledLink = styled.a`
  cursor: pointer;
	
	> svg {
		width: 24px;
		height: 24px;
	}
`;
const StyledLoader = styled.div`
  padding: 4px 0 0 0;
`;

const FavoriteBtn = ({ isFavorite = false, onClick, isLoading }) => {
	return (
		<StyledLink
			className={`player__interaction-button waves-effect waves-button ${isFavorite ? 'is-active' : ''}`}
			onClick={onClick}
		>
			{
				isLoading
					? (
						<StyledLoader>
							<ClipLoader color="#FFF" size={26}/>
						</StyledLoader>
					)
					: <MdFavorite/>
			}
		</StyledLink>
	);
};

export default FavoriteBtn;
