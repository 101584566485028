import React from 'react';
import {useSelector} from 'react-redux';
import {notification} from 'antd';
import BasketProfileSelect from './BasketProfileSelect';
import BasketTotal from './BasketTotal';

const BasketFooter = ({openDialog}) => {
	const {currentProfileId} = useSelector((state) => state.user);

	const payHandler = (type) => () => {
		if (currentProfileId) {
			openDialog(type);
		} else {
			notification.error({
				message: 'Необходимо указать платежный профиль!',
				description: (
					<div> Если у вас нет платежного профиля, вы можете создать его в личном кабинете
						<br/>
						<a
							className="mt-3 mx-auto button button--default button--success waves-light mr-3 button--waves"
							href="/personal/profiles/"
						>
							Создать профиль
						</a>
					</div>
				),
				placement: 'bottomRight',
				duration: 10000,
			});
		}
	};

	return (
		<footer className="cart__footer mt-5">
			<div className="row">
				<div className="col-6">
					<p className="title--weight--medium text--middle pb-3">Оформить заказ на</p>
					<div className="select--default py-4">
						<BasketProfileSelect/>
					</div>
					<a
						className="button button--default button--bordered waves-light mt-4 button--waves"
						href="/personal/profiles/"
					>
						<span
							className="button__content"
						>
							Создать профиль                                                                                                                                      </span>
					</a>
				</div>
				<div className="col-6 text-right">
					<BasketTotal/>
					<p className="text--small text--gray pb-5">Факт оплаты заказа означает полное и безоговорочное
						принятие <a className="text--underlined" href="#">оферты</a>
					</p>
					<div className="d-flex mt-5 justify-content-end">
						<button
							type="button"
							className="button button--default button--success waves-light mr-3 button--waves"
							onClick={payHandler(1)}
						>
							<span className="button__content">Оплата по счету</span>
						</button>
						<button
							className="button button--default button--gray waves-light button--waves is-disabled"
							type="button"
							//	onClick={payHandler(2)}
							disabled
						>
							<span className="button__content">Оплата картой</span>
						</button>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default BasketFooter;
