import {createSlice} from '@reduxjs/toolkit';
import {getPaymentProfile} from '../actions/userActions';
import {
createProfile, deleteProfile, editProfile, getListToCreate,
} from '../actions/profilesActions';
import {getSubscribe} from '../actions/subscribeActions';

const initialState = {
	isAuth: false,
	paymentProfile: [],
	currentProfileType: null,
	currentProfileId: null,
	profileSignature: null,
	profileStamp: null,
	profileTypes: [],
	subscribes: [],
	result: null,
	page: 'catalog',
};

const playerSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserProperty: (state, {payload}) => {
			state[payload.property] = payload.value;
		},
		clearResult: (state) => {
			state.result = null;
		},
		setPage: (state, {payload}) => {
			state.page = payload;
		},
	},
	extraReducers: {
		[getPaymentProfile.fulfilled]: (state, {payload}) => {
			if (payload.data && !payload.data.error) {
				state.paymentProfile = payload.data;
			}
			state.isAuth = !payload.data.error;
		},
		[getListToCreate.fulfilled]: (state, {payload}) => {
			if (payload.data) {
				state.profileTypes = Object.keys(payload.data).map((key) => ({label: payload.data[key], value: key}));
			}
		},
		[getSubscribe.fulfilled]: (state, {payload}) => {
			if (payload.data && !payload.data.error) {
				state.subscribes = payload.data;
			}
		},
		[createProfile.pending]: (state) => {
			state.result = null;
		},
		[createProfile.fulfilled]: (state, {payload}) => {
			if (payload.data.result) {
				state.result = 'Профиль успешно создан';
				state.currentProfileId = payload.data.profile_id;
			}
		},
		[editProfile.fulfilled]: (state, {payload}) => {
			if (payload.data.result) {
				state.result = 'Профиль успешно сохранен';
			}
		},
		[deleteProfile.fulfilled]: (state) => {
			state.currentProfileId = null;
		},
	},
});

export const {
	setUserProperty, clearResult,
	setPage,
} = playerSlice.actions;
export default playerSlice.reducer;
export const userState = (state) => state.user;
