import {css} from 'styled-components';

const FontFamily = () => css`
  //font-family: Arial, Tahoma, Helvetica, sans-serif;
`
export const h1 = () => css`
  font-size: 24px !important;
  ${FontFamily()};
`;

export const scrollBar = () => css`
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #EEEEEE;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #717171;
    border-radius: 6px;
    box-shadow: inset 1px 1px 10px #717171;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: black;
  }
`;