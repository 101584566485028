import React, {
	useCallback, useEffect, useMemo, useRef,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useWindowSize} from 'react-use';
import {useFilter} from './context/filterContext';
import {getPlaylistInfo} from '../../store/actions/catalogActions';
import {useCatalog} from './context/catalogContext';
import BackBtn from '../../components/BackBtn';
import CatalogPlaylistDetail from './CatalogPlaylistDetail';
import {clearPlayListInfo, clearTracks} from '../../store/reducers/catalogSlice';
import CatalogPlaylistList from './CatalogPlaylistList';

const CatalogPlaylists = () => {
	const ref = useRef();
	const {playlists, pageNav} = useSelector((state) => state.catalog);
	const dispatch = useDispatch();
	const {getFilters} = useFilter();
	const {playlistId, setPlaylist, getCurrentPageData} = useCatalog();
	const {height} = useWindowSize();

	const filters = useMemo(() => {
		return {...getFilters()};
	}, [getFilters]);

	const backBtnHandler = () => {
		setPlaylist(undefined);
		dispatch(clearTracks());
		dispatch(clearPlayListInfo());
	};

	const getData = useCallback(() => {
		getCurrentPageData({
			filters,
			infinity: true,
			page: pageNav.curPage + 1,
		});
	}, [getCurrentPageData, filters, pageNav]);

	useEffect(() => {
		if (playlistId) {
			dispatch(getPlaylistInfo({id: playlistId}));
		}
	}, [playlistId, dispatch]);

	return (
		<div className="catalog__content catalog__content--playlists">
			{
				playlistId
					? (
						<>
							<BackBtn onClick={backBtnHandler}/>
							<CatalogPlaylistDetail/>
						</>
					)
					: (
						<>
							<div
								id="scrollableDiv"
								className="Catalog__content-scroll-container"
								style={{
									height: height - 300,
									// width: 1530,
									overflow: 'auto',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<InfiniteScroll
									ref={ref}
									dataLength={playlists.length}
									next={getData}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									hasMore={pageNav.curPage !== pageNav.allPage}
									loader={<h3>Загрузка ...</h3>}
									scrollableTarget="scrollableDiv"
								>
									<CatalogPlaylistList/>
								</InfiniteScroll>
							</div>
						</>
					)
			}
		</div>
	);
};

export default CatalogPlaylists;
