import { createSlice } from '@reduxjs/toolkit';
import {
	applyCouponBasket, createOrderTracks,
	createOrderSubscribe,
	getBasketSubscribesPrice,
	getBasketTracksPrice,
	getTracksBasket,
} from '../actions/basketActions';

const initialState = {
	tracks: [],
	coupon: null,
	couponName: '',
	orderLink: null,
	message: '',
	licenses: [],
	licenseBasket: {},
	activeLicense: {},
	payType: 1,
	isLoading: false,
};

const basketSlice = createSlice({
	name: 'basket',
	initialState,
	reducers: {
		setBasketProperty: (state, { payload }) => {
			state[payload.property] = payload.value;
		},
		setActiveLicense: (state, { payload }) => {
			state.activeLicense = state.licenses.find((licence) => licence.id === payload.id);
		},
		setPayType: (state, { payload }) => {
			state.payType = payload;
		},
	},
	extraReducers: {
		[getBasketTracksPrice.pending]: (state) => {
			state.isLoading = true;
		},
		[getBasketSubscribesPrice.pending]: (state) => {
			state.isLoading = true;
		},
		[getBasketTracksPrice.rejected]: (state) => {
			state.isLoading = false;
		},
		[getBasketSubscribesPrice.rejected]: (state) => {
			state.isLoading = false;
		},
		[getTracksBasket.fulfilled]: (state, { payload }) => {
			if (payload.data.data) {
				state.tracks = payload.data.data;
				state.message = '';
			}
			if (!payload.data.result) {
				state.message = payload.data.message;
			}
			state.isLoading = false;
		},
		[getBasketTracksPrice.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (!payload.data.message) {
				state.licenses = payload.data;
				state.activeLicense = payload.data[payload.data.length - 1];
			}
		},
		[getBasketSubscribesPrice.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (!payload.data.message) {
				state.licenses = payload.data.items;
			}
		},
		[applyCouponBasket.fulfilled]: (state, { payload }) => {
			state.coupon = payload.data;
		},
		[createOrderTracks.fulfilled]: (state, { payload }) => {
			if (payload.data.result) {
				state.orderLink = payload.data.redirect_link;
			}
		},
		[createOrderSubscribe.fulfilled]: (state, { payload }) => {
			if (payload.data.result) {
				state.orderLink = payload.data.redirect_link;
			}
		},
	},
});

export const { setBasketProperty, setActiveLicense, setPayType } = basketSlice.actions;
export default basketSlice.reducer;
