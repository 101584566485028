import {$data} from '../../helpers/data';
import is from 'is_js';

export default class StorageSaver {
  constructor(storageKey, json = false, store = localStorage) {

    this.storageKey = storageKey;
    this.dataIsObject = json;
    this.store = store;

    if (this.dataIsObject) {
      this.data = this.get() ;
    }
  }

  _saveToStorage(data) {
    this.store.setItem(this.storageKey, this.dataIsObject ? JSON.stringify(data) : data)
  }

  save(data) {
    if (this.dataIsObject) {
      this.data = $data.deepAssign(this.data, data);
      this._saveToStorage(this.data)
    } else {
      this._saveToStorage(data)
    }
  }

  clear() {
    if (this.dataIsObject) {
      this._saveToStorage({})
    } else {
      this._saveToStorage('')
    }
  }

  get() {
    const isObj = this.dataIsObject;
    const storeVal = this.store.getItem(this.storageKey);

    if (is.null()) return isObj ? {} : null;

    if (isObj) {
      return JSON.parse(storeVal) || {}
    } else {
      return storeVal
    }
  }
}