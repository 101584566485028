import variables from '../../variables';
import Model from './Model';
import is from 'is_js';
import {warn, isElement, preventDefault} from '../../helpers/_utilities';
import CarouselController from './CarouselCreator';
import initWaves from '../components/waves';
import LazyLoad from 'vanilla-lazyload';
import {$dom} from '../../helpers/dom';
import {$data} from '../../helpers/data';
import initNavFollower from '../components/navFollower';
import initTabs from '../components/tabs';
import smoothScrollbar from '../components/scrollbar';
import batchAnimation from '../animations/batch';
import numbersIncrement from '../components/numbersIncrement';
import {$events} from '../../helpers/events';
import Search from './Search';
import initLoader from '../components/loader';
import initDropdown from '../components/dropdown';
import modalAuthorize from '../components/modalAuthorize';
import initCollapse from '../components/collapse';
import ankersFunctional from '../components/ankersFunctional';

const {
  removeClass,
  addClass,
  toggleClass,
  exist,
  getAll,
  each,
  hasClass,
  attr,
  callAll
} = $dom;

export default class Page extends Model {

  constructor(options = {}) {

    super(options);

    this.exist = true;

    if (is.not.domNode(this.rootElement)) {
      if (this.options.debug && this.options.notifyRootAbsence) warn(`Instance Page with name "${this.name}" has no root element provided`, 'Page-class');
      this.exist = false;
      return;
    }

    this.defaults = {
      carousels: [],
      batch: true,
      resizeDependentMethods: [],
      loader: false,
      searchSettings: {
        name: 'search',
        rootElementId: 'js-search'
      },
      modalSelector: '.page__modal',


    };

    this.options = $data.deepAssign(this.defaults, options);
    this.carousels = this.options.carousels;
    this.CarouselController = new CarouselController();

    this.waves = null;
    this.imagesLazyLoad = this.lazyLoadInit();
    this.navFollowers = initNavFollower.call(this);
    this.tabs = initTabs.call(this);
    this.dropdown = initDropdown();
    this.collapse = initCollapse.call(this);
    this.ankersFunctional = ankersFunctional();
    // this.smoothScrollbar = smoothScrollbar();
    this.batchAnimations = batchAnimation({
      onEnterOnly: true
    });
    this.numbersIncrement = numbersIncrement(3, true);
    this.loader = initLoader();
    this.modalAuthorize = modalAuthorize();

    this.methodsUnderControl = [
      this.imagesLazyLoad,
      this.navFollowers,
      this.tabs,
      this.ankersFunctional,
      this.numbersIncrement,
      this.dropdown,
      this.collapse,
      this.modalAuthorize
    ];

    this.search = new Search(this.options.searchSettings);

    if (this.options.loader) this.loader = initLoader();

    this.options.batch = this.batchAnimations.hasOwnProperty('init');

    this.setPageBlur = this.setPageBlur.bind(this);
    this.removePageBlur = this.removePageBlur.bind(this);
  }

  lazyLoadInit() {
    const $self = this;
    let lazyInstance = null;

    return {
      init() {
        lazyInstance = new LazyLoad({
          elements_selector: '.'+variables.classNames.lazyImage+'--static',
          class_loaded: variables.classNames.lazyLoaded,
          class_loading: variables.classNames.lazyLoading,
          class_entered: 'is-entered',
          data_src: 'lazy-src',
          data_bg: 'lazy-bg',
          threshold: 300,
          callback_loaded(el) {
            const nextEl = el.nextElementSibling;
            if (isElement(nextEl) && nextEl.classList.contains(variables.classNames.lazyPreloader)) $dom.remove(nextEl)
          }
        });

        return $self;
      },
      destroy() {
        if (lazyInstance instanceof LazyLoad) {
          lazyInstance.destroy();
          lazyInstance = null
        }
        return $self;
      },
      get instance() {
        return lazyInstance
      }
    }
  }

  _methodsRun(action) {
    for (let i = 0; i < this.methodsUnderControl.length; i++) {
      const m = this.methodsUnderControl[i];
      if (m) {
        const f = m[action];

        if (is.function(f)) f()
      }
    }
  }

  resizeDependentMethods(action) {
    let methods = [];
    for (let i = 0; i < this.options.resizeDependentMethods.length ; i++) {

      let method = this.options.resizeDependentMethods[i];

      methods.push(method);

      switch (action) {
        case 'add':
          method.call(this);
          $events.resize('on', method);
          break;

        case 'remove':
          $events.resize('off', method);
          break;
      }
    }

    return methods;
  }

  setPageBlur() {
    addClass(this.rootEl, variables.classNames.blurred);
  }

  removePageBlur() {
    removeClass(this.rootEl, variables.classNames.blurred);
  }

  listeners(action) {
    switch (action) {
      case 'add':
        $events.delegate
          .on(variables.customEventNames.modal.open, this.options.modalSelector, this.setPageBlur)
          .on(variables.customEventNames.modal.close, this.options.modalSelector, this.removePageBlur)
        ;


        break;

      case 'remove':
        $events.delegate
          .off(variables.customEventNames.modal.open, this.options.modalSelector, this.setPageBlur)
          .off(variables.customEventNames.modal.close, this.options.modalSelector, this.removePageBlur)
        ;
        break;
    }
  }

  init() {
    this.carousels = this.CarouselController.create(this.options.carousels);
    this.waves = initWaves();

    // this.smoothScrollbar.initAll();
    this._methodsRun('init');
    this.resizeDependentMethods('add');

    if (this.options.batch) this.batchAnimations.init();
    if (this.options.loader) this.loader.init();

    this.search.init();

    this.listeners('add');

    super.init(this);
    return this;
  }

  destroy() {
    this.carousels = this.CarouselController.destroy();

    this.waves = null;

    // this.smoothScrollbar.destroy();
    this._methodsRun('destroy');
    this.resizeDependentMethods('remove');

    if (this.options.batch) this.batchAnimations.destroy();
    if (this.options.loader) this.loader.destroy();

    this.search.destroy();

    this.listeners('remove');
    super.destroy(this);
    return this;
  }
}

