import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import {notification, Popconfirm} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {useWindowSize} from 'react-use';
import ProfilesSelect from './components/ProfilesSelect';
import {getPaymentProfile} from '../../store/actions/userActions';
import ProfileTypes from './components/ProfileTypes';
import ProfilesForm from './components/ProfilesForm';
import {scrollBar} from '../../styles/sc-mixins';
import ProfileFileUploader from './components/ProfileFileUploader';
import ProfilesImage from './components/ProfilesImage';
import Modal from '../../components/Modal';
import {
	createProfile, deleteProfile, editProfile, getListToCreate,
} from '../../store/actions/profilesActions';
import {clearResult, setUserProperty} from '../../store/reducers/userSlice';

const StyledWrapper = styled.div`
padding: 30px 0.9375rem 110px;
  height:  ${({height}) => height - 200}px;
  overflow-y: auto;
  ${scrollBar()};
 
`;

const StyledSelects = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  align-items: center;
  gap:30px;
	.top__buttons {
		display: flex;
	 	align-items: center;
	    gap:30px;
	}
@media screen and (max-width: 1200px) {
grid-template-columns: 1fr 1fr;
.top__buttons {
		grid-column: span 2;
		justify-content: flex-end;
	}
}
`;

const numericValues = ['PHONE', 'BIK', 'CORRESPONDENT_ACCOUNT', 'PAYMENT_ACCOUNT', 'INN', 'OGRNIP'];

const Profiles = () => {
	const {height} = useWindowSize();
	const dispatch = useDispatch();
	const {
		profileStamp, profileSignature, currentProfileId,
		paymentProfile, currentProfileType, profileTypes,
		result,
	} = useSelector((state) => state.user);
	const modal = useRef(null);
	const [name, setName] = useState('');
	const [currentProfile, setCurrentProfile] = useState(null);
	const [profileType, setProfileType] = useState(null);
	const [stamp, setStamp] = useState('');
	const [signature, setSignature] = useState('');
	const [changes, setChanges] = useState({});
	const [canSave, setCanSave] = useState(true);

	const inputHandler = (id, code) => (evt) => {
		let {value} = evt.target;

		if (numericValues.includes(code)) {
			value = value.toString().replace(/[\D]/ig, '');
		}
		changes[id] = value;
		const newChanges = {...changes, [id]: value};
		setChanges(newChanges);
	};

	const openDialog = () => {
		modal.current.classList.remove('page__modal');
	};

	const closeDialog = () => {
		modal.current.classList.add('page__modal');
	};

	const renameHandler = (evt) => {
		const {value} = evt.target;
		setName(value);
	};

	const addProfileHandler = useCallback(() => {
		if (name && profileType) {
			dispatch(createProfile({name, id_profile: profileType.value}));
			closeDialog();
		}
	}, [dispatch, name, profileType]);

	const submitHandler = useCallback(() => {
		const profile = new FormData();
		profile.append('id', currentProfileId);
		profile.append('name', name);
		Object.keys(changes).forEach((key) => {
			profile.append(key, changes[key]);
		});

		if (profileStamp) {
			profile.append('stamp', profileStamp);
		}
		if (profileSignature) {
			profile.append('signature', profileSignature);
		}

		dispatch(setUserProperty({property: 'profileSignature', value: null}));
		dispatch(setUserProperty({property: 'profileStamp', value: null}));

		dispatch(editProfile({profile}));
		closeDialog();
	}, [currentProfileId, changes, profileStamp, profileSignature, dispatch, name]);

	const deleteProfileHandler = useCallback(() => {
		dispatch(deleteProfile({id: currentProfileId}));
	}, [currentProfileId, dispatch]);

	useEffect(() => {
		let canSave = true;
		if (currentProfile) {
			currentProfile.props.forEach((prop) => {
				const isRequired = prop.required === 'Y';
				if (isRequired) {
					const value = typeof changes[prop.id] !== 'undefined'
						? changes[prop.id]
						: prop.value;
					canSave = canSave && !!value;
				}
			});
		}
		setCanSave(canSave);
	}, [changes, currentProfile]);

	useEffect(() => {
		const currentProfile = paymentProfile.find((profile) => +profile.id === +currentProfileId);
		if (currentProfile) {
			setName(currentProfile.name);
			setCurrentProfile(currentProfile);
			const currentProfileStamp = currentProfile.props.find((prop) => prop.code === 'STAMP');
			const currentProfileSignature = currentProfile.props.find((prop) => prop.code === 'SIGNATURE');
			if (!!currentProfileStamp) {
				setStamp(currentProfileStamp.value ? (window.location.origin + currentProfileStamp.value) : '');
			} else {
				setStamp(-1);
			}
			if (!!currentProfileSignature) {
				setSignature(currentProfileSignature.value ? (window.location.origin + currentProfileSignature.value) : '');
			} else {
				setStamp(-1);
			}
		} else {
			setName('');
			setCurrentProfile(null);
			setStamp('');
			setSignature('');
		}
	}, [currentProfileId, paymentProfile]);

	useEffect(() => {
		dispatch(getPaymentProfile());
	}, [dispatch]);

	useEffect(() => {
		if (currentProfileType) {
			const profileType = profileTypes.find((type) => type.value === currentProfileType);
			setProfileType(profileType);
		} else {
			setProfileType(null);
		}
	}, [currentProfileType, profileTypes]);
	useEffect(() => {
		if (profileStamp) {
			setStamp(URL.createObjectURL(profileStamp));
		}
	}, [profileStamp]);

	useEffect(() => {
		if (profileSignature) {
			setSignature(URL.createObjectURL(profileSignature));
		}
	}, [profileSignature]);

	useEffect(() => {
		dispatch(getListToCreate());
	}, [dispatch]);

	useEffect(() => {
		if (result) {
			notification.success({
				message: result,
				placement: 'bottomLeft',
				duration: 5,
			});
			dispatch(clearResult());
		}
	}, [dispatch, result]);
	return (
		<StyledWrapper height={height}>

			<div className="cart__list-header mb-3" style={{fontSize: 18}}>
				Платежный профиль
				пользователя
			</div>
			<StyledSelects>

				{
					!!paymentProfile.length
					&& (
						<div className="">
							<div className="select--default">
								<ProfilesSelect/>
							</div>
						</div>
					)
				}
				<div className="">
					<div className="select--default">
						<ProfileTypes/>
					</div>
				</div>
				<div className="top__buttons">

					{
						(currentProfileId || currentProfileType) && (
							<div className="">
								<button
									className="button button--default button--bordered waves-light button--waves"
									type="button"
									onClick={openDialog}
								><span
									className="button__content"
								>
																	{currentProfileId ? 'Переименовать профиль' : 'Далее'}
           </span>
								</button>
							</div>
						)
					}
					{
						currentProfileId && (
							<div className="">
								<Popconfirm
									title="Вы уверены, что хотите удалить профиль？"
									okText="Да"
									cancelText="Нет"
									onConfirm={deleteProfileHandler}
									okButtonProps={{
										className: 'button button--default button--success waves-light button--waves',
									}}
									cancelButtonProps={{
										className: 'button button--default button--bordered waves-light button--waves',
									}}
									className="confirm-popup"
								>
									<button
										className="button button--default button--bordered waves-light button--waves"
										type="button"
									><span className="button__content">Удалить профиль</span>
									</button>
								</Popconfirm>
							</div>
						)
					}
				</div>

			</StyledSelects>

			{
				currentProfileId && (
					<>
						<section className="pb-5 mb-3">
							<h2 className="title title--weight--medium text--middle pb-4 mt-4">
								{
									currentProfile && <>{currentProfile.type_name}: {currentProfile.name}</>
								}
							</h2>
							<div className="row">
								<div className="col-7">
									<ProfilesForm
										inputHandler={inputHandler}
										changes={changes}
										profile={currentProfile}
									/>
								</div>
							</div>
						</section>
						{
							(stamp !== -1 || signature !== -1) && (
								<section className="pb-5 mb-5">
									<h2 className="title title--weight--medium text--middle pb-4">Факсимиле</h2>
									<div className="row">
										{
											signature !== -1 && (
												<div className="col-auto">
													<ProfileFileUploader
														title="Загрузить подпись"
														fileType="profileSignature"
													/>
												</div>
											)
										}
										{
											stamp !== -1 && (
												<div className="col-auto">
													<ProfileFileUploader
														title="Загрузить печать"
														fileType="profileStamp"
													/>
												</div>
											)
										}
									</div>
									<div className="row">
										{
											signature !== -1 && (
												<div className="col-auto">
													<ProfilesImage src={signature} alt="подпись"/>
												</div>
											)
										}
										{
											stamp !== -1 && (
												<div className="col-auto">
													<ProfilesImage src={stamp} alt="печать"/>
												</div>
											)
										}
									</div>
									<p className="personal__profiles-facsimile-requirements pt-4">
										Формат файла должен быть
										<span className="accent mx-2">
												PNG
											</span>
										или
										<span
											className="accent ml-2"
										>
												JPG
           </span>
									</p>
								</section>
							)
						}
						{
							canSave
								? (
									<button
										onClick={submitHandler}
										className="button button--default button--success waves-light button--waves"
										type="submit"
									><span className="button__content">Сохранить изменения</span>
									</button>
								)
								: (
									<button
										className="button button--default waves-light button--waves button--gray"
										type="button"
									><span className="button__content">Сохранить изменения</span>
									</button>
								)
						}
					</>
				)
			}

			<Modal
				ref={modal}
				title="Введите имя платежного профиля"
				onClose={closeDialog}
			>
				<div className="mt-5 d-flex align-items-center justify-content-center w-100">
					<input className="form__field" type="text" value={name} onChange={renameHandler}/>
				</div>
				<div className="mt-5 d-flex align-items-center justify-content-center">
					<button
						className="button button--success waves-light button--waves button--default"
						type="submit"
						onClick={currentProfileId ? submitHandler : addProfileHandler}
					><span className="button__content">Сохранить</span>
					</button>
				</div>
			</Modal>
		</StyledWrapper>
	);
};

export default Profiles;
