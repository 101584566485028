import {createSlice} from '@reduxjs/toolkit';
import {addTrack} from '../actions/subscribeActions';

const initialState = {
	subscribe: [],
	isLoading: false,
	downloadType: null,
	downloadTrackId: null,
	error: null,
};

const setError = (state, action) => {
	state.isLoading = false;
	state.error = action.payload;
};
const setPending = (state) => {
	state.isLoading = true;
	state.error = null;
};
const setFulfilled = (state) => {
	state.isLoading = false;
	state.error = null;
};

const subscribeSlice = createSlice({
	name: 'subscribeList',
	initialState,
	reducers: {
		clearType: (state) => {
			state.downloadType = null;
			state.downloadTrackId = null;
		},
		clearError: (state) => {
			state.error = null;
		},
	},
	extraReducers: {
		[addTrack.pending]: setPending,
		[addTrack.fulfilled]: (state, {payload}) => {
			console.log('payload', payload);
			state.isLoading = false;
			if (payload.result) {
				state.downloadType = payload.type;
				state.downloadTrackId = payload.trackId;
			}
			if (payload.error) {
				state.downloadType = null;
				state.downloadTrackId = null;
				state.error = payload.message;
			}
		},
		// [deleteFavorite.pending]: (state, action) => {
		// 	state.loadingTrackId = action.meta.arg.trackId;
		// },
		// [deleteFavorite.fulfilled]: setFulfilled,
		// [getFavorite.pending]: setPending,
		// [getFavorite.fulfilled]: (state, action) => {
		// 	state.favorite = action.payload;
		// 	state.isLoading = false
		// },
		// [addFavorite.rejected]: setError,
		// [addFavorite.rejected]: setError,
		// [deleteFavorite.rejected]: setError,
		// [deleteFavorite.rejected]: setError,
		// [getFavorite.rejected]: setError,
	}
});

export const {clearType, clearError} = subscribeSlice.actions;
export default subscribeSlice.reducer;
