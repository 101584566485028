import React from 'react';
import {useCatalog} from './context/catalogContext';
import {useFilter} from './context/filterContext';

const CatalogNavItem = ({navItem}) => {
	const {
		type, setPageHandler,
	} = useCatalog();
	const {
clearFilters, setSearches, setFilterHandler, setSearch,
} = useFilter();

	const navHandler = (id) => {
		clearFilters();
		setSearches([]);
		setFilterHandler(null)();
		setPageHandler(id)();
		setSearch('');
	};

	return (
		<a
			className={`catalog__nav-link title title--regular title--weight--bold js-catalog-nav-link ${type === navItem.id ? 'is-active' : ''}`}
			onClick={() => navHandler(navItem.id)}
			style={{
				cursor: 'pointer',
			}}
		>
			{navItem.title}
		</a>
	);
};

export default CatalogNavItem;
