import React from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {getImagePath} from '../../utils/image-utils';
import {useCatalog} from './context/catalogContext';
import {resetPageNav} from '../../store/reducers/catalogSlice';
import {useWindowSize} from 'react-use';

const StyledLink = styled.div`
  cursor: pointer;
`;

const CatalogCollectionList = ({collections}) => {
	const {setCollection} = useCatalog();
	const dispatch = useDispatch();
	const {height} = useWindowSize();

	const setCollectionHandler = (id) => {
		setCollection(id);
		dispatch(resetPageNav());
	};

	return (
		<div
			className="Catalog__content-scroll catalog__content catalog__content--collections mt-5"
			style={{
				height: height - 350,
			}}
		>
			<ul className="catalog__content-row">
				{
					collections.map((collection) => {
						const {
							id, poster, name, description, tracksQuantity, albumsQuantity,
						} = collection;
						const image = getImagePath(poster);

						return (
							<li key={id} className="catalog__content-col1">
								<StyledLink onClick={() => setCollectionHandler(id)}>
									<section className="card__collection">
										<div className="card__collection-image">
											<div className="page__image">
												<img
													className="lazy__image lazy__image--static is-entered is-loaded"
													alt={name}
													src={image}
												/>

											</div>
										</div>
										<div className="card__collection-content">
											<h3 className="title title--weight--medium text--middle pb-3">
												{name}
											</h3>
											<div
												className="card__collection-description text--gray text--small"
											>
												{description}
											</div>
											<p className="text--small">
											<span className="pr-4">
												<span className="title--weight--medium">
													Треки:
												</span>
                              					<output
													className="title--weight--light"
												>
													&nbsp;{tracksQuantity}
                                   </output>
           </span>
												<span>
												<span
													className="title--weight--medium"
												>
													Альбомы:
												</span>
                            				  <output className="title--weight--light">
												  &nbsp;{albumsQuantity}
                                  </output>
            </span>
											</p>
										</div>
									</section>
								</StyledLink>
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};

export default CatalogCollectionList;
