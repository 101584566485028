import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {useWindowSize} from 'react-use';
import Player from '../Player/Player';
import PlayerContextProvider from '../Player/context/playerContext';
import {getFavorite, getListened} from '../../store/actions/userListActions';
import CatalogTrackList from '../catalog/CatalogTrackList';
import {scrollBar} from '../../styles/sc-mixins';
import FilterContextProvider from '../catalog/context/filterContext';
import {setPage} from '../../store/reducers/userSlice';
import Loader from '../../components/Loader';
import CatalogContextProvider from '../catalog/context/catalogContext';
import {getPaymentProfile} from '../../store/actions/userActions';

const StyledContainer = styled.div`
  padding: 30px 0.9375rem 110px;
  height:  ${({height}) => height - 200}px;
  overflow-y: auto;
  ${scrollBar()};
`;

const History = () => {
	const dispatch = useDispatch();
	const {height} = useWindowSize();
	const {listened, isListenedListLoading} = useSelector((state) => state.userList);

	useEffect(() => {
		dispatch(getFavorite());
		dispatch(getListened());
		dispatch(getPaymentProfile());
	}, [dispatch]);

	useEffect(() => {
		dispatch(setPage('history'));
	}, [dispatch]);

	return (
		<CatalogContextProvider>
			<FilterContextProvider>
				<PlayerContextProvider>
					<StyledContainer height={height}>
						<div className="cart__list-header mb-3" style={{fontSize: 18}}>
							{
								!!listened.length
									? <span>Последние треки: {listened.length}</span>
									: <span>Список прослушанных треков пуст </span>
							}
						</div>

						{
							isListenedListLoading && !listened.length
							&& <div className="h-100 w-100 d-flex align-items-center justify-content-center">
								<Loader/>
							</div>
						}
						{
							!!listened.length
							&& <CatalogTrackList tracks={listened} isLoading={isListenedListLoading}/>
						}

					</StyledContainer>
					<Player/>
				</PlayerContextProvider>
			</FilterContextProvider>
		</CatalogContextProvider>
	);
};

export default History;
